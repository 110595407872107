<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <ActasReunionFormulario
            v-auth-acl="'gh-act-act_reu-insert'"
            :idreferencia="idreferencia"
            :referencia="referencia"
            @open="open"
            @close="close"
          ></ActasReunionFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ActasReunion',
  components: {
    ActasReunionFormulario: () => import('../components/ActasReunionFormulario')
  },
  props: {
    idreferencia: {
      type: String,
      default: ''
    },
    referencia: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    page: {
      title: 'Actas'
    },
    breadcrumbs: [
      {
        text: 'Actas',
        disabled: true
      },
      {
        text: 'Acta de Reunión',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
